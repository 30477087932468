<template>
  <section class="p-4">
    <v-tabs fixed-tabs centered v-model="tab" active-class="bg-primary">
      <v-tab
        key="folders"
        active-class="bg-app-primary text-white rounded"
        class="tab-list"
        >Carpetas y Subcarpetas</v-tab
      >
      <v-tab
        class="tab-list"
        active-class="bg-app-primary text-white rounded"
        key="names"
        >Nombres de Documentos</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="projects">
        <maintainer-folders></maintainer-folders>
      </v-tab-item>
      <v-tab-item key="presale">
        <maintainer-names></maintainer-names>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
import MaintainerFolders from "./folders/index.vue";
import MaintainerNames from "./document_names/index.vue";
export default {
  components: {
    MaintainerFolders,
    MaintainerNames,
  },
  data() {
    return {
      tab: "folders",
    };
  },
};
</script>

<style></style>
