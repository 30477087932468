<template>
  <section class="px-16 mt-8">
    <loading-flux :value="loadingActions"></loading-flux>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <section class="d-flex flex-column flex-sm-row justify-content-between">
          <h2 class="my-3">Carpetas y Subcarpetas de Documentos</h2>
        </section>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-3">
        <v-btn
          @click="openModalUpdateOrCreateFolder(null)"
          :disabled="loading"
          color="primary"
          :dark="false"
          class="text-white float-right mt-4"
        >
          <i class="mdi mdi-plus me-1"></i>
          Crear Carpeta
        </v-btn>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12" class="mb-2">
        <v-text-field
          label="Búsqueda"
          @input="($event) => getFoldersDocumentsData()"
          v-model="filters.filter"
          clearable
          class="mt-3"
          placeholder="Búsqueda"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="5" md="5" sm="12" class="mb-2">
        <v-autocomplete
          class="mt-3"
          placeholder="Filtrar por Unidad de Negocio"
          v-model="filters.business_unit"
          :items="activeBusinessUnits"
          @change="($event) => getFoldersDocumentsData()"
          item-text="name"
          item-value="code"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12" class="mb-2">
        <v-autocomplete
          v-model="filters.items"
          class="mt-3"
          @change="($event) => getFoldersDocumentsData()"
          :items="[5, 10, 15, 20, 25, 100]"
          label="Items por Página"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="mb-2">
        <v-data-table
          :key="keyTableFolders"
          :page.sync="filters.page"
          :headers="tableHeaders"
          :items="foldersDocuments.items ? foldersDocuments.items : []"
          item-key="code"
          :items-per-page="filters.items"
          class="elevation-1"
          mobile-breakpoint="993"
          hide-default-footer
          :loading="loading"
          no-data-text="No hay Datos para Mostrar"
          loading-text="Cargando Carpetas... Espere Por favor"
        >
          <template v-slot:item.name="{ item }">
            <div class="link-text text-left">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item.business_unit="{ item }">
            <div class="link-text text-left">
              {{ item.business_unit.name }}
            </div>
          </template>
          <template v-slot:item.type_folder="{ item }">
            <v-select
              :items="optionsFolders"
              :value="
                !item.parent ? TYPES_FOLDER.folder : TYPES_FOLDER.subfolder
              "
              item-text="text"
              item-value="value"
              @change="updateFolderType($event, item)"
            >
              <template v-slot:selection="{ item: selected }">
                <v-chip
                  pill
                  :color="item.parent ? 'success' : 'primary'"
                  class="text-white text-left"
                >
                  {{ !item.parent ? "Carpeta" : "Subcarpeta" }}
                </v-chip>
              </template>
            </v-select>
          </template>
          <template v-slot:item.folder_container="{ item }">
            <div class="link-text text-center" v-if="!item.parent">-</div>
            <v-autocomplete
              v-else
              class="mt-3"
              :value="item.parent ? item.parent.id : null"
              :items="
                [
                  {
                    id: item.parent.id,
                    name: item.parent.name,
                    business_unit: item.parent.business_unit,
                  },
                  ...foldersDocumentsFromBusinessUnit.items,
                ].filter(
                  (element) =>
                    element.id !== item.id &&
                    !item.children.some((child) => child.id === element.id)
                )
              "
              label="Selecciona la carpeta"
              item-text="name"
              item-value="id"
              no-data-text="Sin carpetas disponibles"
              @update:search-input="searchFoldersDocuments($event, item)"
              @focus="searchFoldersDocuments(null, item)"
              @change="updateParentFolder($event, item)"
            >
            </v-autocomplete>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex align-items-center justify-content-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="openModalUpdateOrCreateFolder(item, true)"
                    icon
                    color="warning"
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    class="mx-2"
                  >
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Editar Carpeta</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="deleteFolder(item)"
                    icon
                    color="red darken-2"
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    class="mx-2"
                  >
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar Carpeta</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="filters.page"
            :length="foldersDocuments ? foldersDocuments.total_pages : 1"
            :total-visible="8"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <modal-update-or-create-folder
      :modalAction="dialogUpdateOrCreateFolder"
      :closeModalCreateOrUpdateFolder="closeModalCreateOrUpdateFolder"
      :currentFolder="currentFolder"
      :parentFolder="parentFolder"
      @folderCreatedOrUpdated="getFoldersDocumentsData(1)"
    >
    </modal-update-or-create-folder>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { TYPES_FOLDER } from "@/constants/documents/maintainers";
import ModalUpdateOrCreateFolder from "./ModalUpdateOrCreateFolder.vue";
import { authUsuarioComputed } from "@/state/helpers";
import { ACTIVE_BUSINESS_UNITS } from "@/constants/presale";

export default {
  components: { ModalUpdateOrCreateFolder },
  data() {
    return {
      keyTableFolders: 1000,
      TYPES_FOLDER,
      dialogUpdateOrCreateFolder: false,
      currentFolder: null,
      parentFolder: null,
      loading: false,
      loadingActions: false,
      debounce: null,
      debounceFolders: null,
      debounceTime: 500,
      filters: {
        page: 1,
        items: 10,
        filter: "",
        business_unit: null,
      },
      tableHeaders: [
        {
          value: "name",
          text: "Nombre de la Carpeta",
          sortable: false,
          width: "20%",
        },
        {
          value: "business_unit",
          text: "Unidad de Negocio",
          sortable: false,
          align: "left",
          width: "15%",
        },
        {
          value: "type_folder",
          text: "Carpeta o Subcarpeta",
          sortable: false,
          width: "12%",
          align: "center",
        },
        {
          value: "folder_container",
          sortable: false,
          align: "center",
          text: "Carpeta Contenedora",
          width: "15%",
        },
        {
          value: "actions",
          sortable: false,
          align: "center",
          text: "Acciones",
          width: "10%",
        },
      ],
    };
  },
  mounted() {
    this.getFoldersDocumentsData();
  },
  methods: {
    ...mapActions({
      getAllfoldersDocuments: "document/getAllfoldersDocuments",
      deleteFolderService: "document/deleteFolderDocument",
      updateOrCreateFolderDocument: "document/updateOrCreateFolderDocument",
    }),
    async getFoldersDocumentsData(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = await setTimeout(async function () {
        self.loading = true;
        self.filters.page = page;
        await self.getAllfoldersDocuments({
          page: self.filters.page,
          items: self.filters.items,
          folder_name: self.filters.filter,
          business_unit_code: self.filters.business_unit,
        });
        self.keyTableFolders = self.keyTableFolders + 1;
        self.loading = false;
      }, self.debounceTime);
    },
    async searchFoldersDocuments(search = null, folder = null) {
      if (
        !search ||
        (typeof search == "string" && search !== folder.parent?.name)
      ) {
        clearTimeout(this.debounceFolders);
        let self = this;
        this.debounceFolders = await setTimeout(async function () {
          self.loadingFolders = true;
          await self.getAllfoldersDocuments({
            filter_from_business_unit: true,
            business_unit_code: folder.business_unit.code,
            filter: search ? search : undefined,
            items: 20,
          });
          self.loadingFolders = false;
        }, self.debounceTime);
      }
    },
    async updateFolderType(value, folder) {
      this.loading = true;
      let foldersResponse = await this.getAllfoldersDocuments({
        filter_from_business_unit: true,
        business_unit_code: folder.business_unit.code,
        items: 20,
        not_committed: true,
      });
      let folders = foldersResponse.folders.filter(
        (item) => !item.parent && item.id !== folder.id
      );
      if (!folders.length && value == TYPES_FOLDER.subfolder) {
        this.loading = false;
        return this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "No hay carpetas disponibles para asignar como carpeta contenedora",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 7000,
          timerProgressBar: true,
        });
      }
      let payload = {
        folder_id: folder.id,
        updated_by: this.user.email,
        name: folder.name,
        parent_id: value == TYPES_FOLDER.folder ? null : folders[0].id,
      };
      const resp = await this.updateOrCreateFolderDocument(payload);
      if (resp.status == 200) {
        this.$swal.fire({
          icon: "success",
          title: "Excelente",
          text: `La carpeta ha sido actualizada correctamente`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
        this.getFoldersDocumentsData();
      }
      if (!resp.status || resp.status === 400 || resp.status === 500) {
        this.loading = false;
        return this.$swal.fire({
          icon: "error",
          title: "Error",
          text:
            resp.status == 500
              ? "Ha ocurrido un error de servidor, intenta nuevamente"
              : resp.data.detail,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 10000,
          timerProgressBar: true,
        });
      }
    },
    async updateParentFolder(value, folder) {
      if (value) {
        const payload = {
          name: folder.name,
          folder_id: folder.id,
          updated_by: this.user.email,
          parent_id: value,
        };
        const resp = await this.updateOrCreateFolderDocument(payload);
        if (resp.status == 200) {
          this.$swal.fire({
            icon: "success",
            title: "Excelente",
            text: `La carpeta contenedora ha sido actualizada correctamente`,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
          this.getFoldersDocumentsData();
        }
        if (!resp.status || resp.status === 400 || resp.status === 500) {
          return this.$swal.fire({
            icon: "error",
            title: "Error",
            text:
              resp.status == 500
                ? "Ha ocurrido un error de servidor, intenta nuevamente"
                : resp.data.detail,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
      }
    },
    openModalUpdateOrCreateFolder(folder, edit = false) {
      if (!edit && folder) {
        this.parentFolder = folder;
      } else {
        this.currentFolder = folder;
      }
      this.dialogUpdateOrCreateFolder = true;
    },
    closeModalCreateOrUpdateFolder() {
      this.currentFolder = null;
      this.parentFolder = null;
      this.dialogUpdateOrCreateFolder = false;
    },
    async deleteFolder(folder) {
      this.$swal
        .fire({
          icon: "warning",
          title:
            "¿Estás seguro de eliminar la carpeta con nombre: " +
            folder.name +
            "?",
          text: "Esta acción no se puede deshacer",
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          confirmButtonColor: "red",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            this.loadingActions = true;
            const resp = await this.deleteFolderService({
              folder_id: folder.id,
            });
            if (resp.status == 200) {
              this.$swal.fire({
                icon: "success",
                title: "Excelente",
                text:
                  "La carpeta con nombre: " +
                  folder.name +
                  " ha sido eliminada",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 5000,
                timerProgressBar: true,
              });
              this.getFoldersDocumentsData();
            }
            if (!resp.status || resp.status === 400) {
              this.loading = false;
              return this.$swal.fire({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 10000,
                timerProgressBar: true,
              });
            }
            this.loadingActions = false;
          }
        });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      foldersDocuments: "document/foldersDocuments",
      foldersDocumentsFromBusinessUnit:
        "document/foldersDocumentsFromBusinessUnit",
      businessUnits: "document/businessUnits",
    }),
    activeBusinessUnits() {
      return this.businessUnits.filter((item) =>
        ACTIVE_BUSINESS_UNITS.includes(item.name)
      );
    },
    optionsFolders() {
      return [
        {
          text: "Carpeta",
          value: TYPES_FOLDER.folder,
        },
        {
          text: "Subcarpeta",
          value: TYPES_FOLDER.subfolder,
        },
      ];
    },
  },
  watch: {
    "filters.page": function (page) {
      this.getFoldersDocumentsData(page);
    },
  },
};
</script>

<style></style>
