<template>
  <section class="px-16 mt-8">
    <loading-flux :value="loadingActions"></loading-flux>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <section class="d-flex flex-column flex-sm-row justify-content-between">
          <h2 class="my-3">Nombres de Documentos</h2>
        </section>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-3">
        <v-btn
          @click="openModalUpdateOrCreateNameDocument(null)"
          :disabled="loading"
          color="primary"
          :dark="false"
          class="text-white float-right mt-4"
        >
          <i class="mdi mdi-plus me-1"></i>
          Crear Nombre de Documento
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12" class="mb-2">
        <v-text-field
          label="Busqueda"
          @input="($event) => getNameDocumentsData()"
          v-model="filters.filter"
          clearable
          class="mt-3"
          placeholder="Busqueda"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12" class="mb-2">
        <v-autocomplete
          v-model="filters.items"
          class="mt-3"
          @change="($event) => getNameDocumentsData()"
          :items="[5, 10, 15, 20, 25, 100]"
          label="Items por Página"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="mb-2">
        <v-data-table
          :page.sync="filters.page"
          :headers="tableHeaders"
          :items="namesDocuments.items ? namesDocuments.items : []"
          item-key="code"
          :items-per-page="filters.items"
          class="elevation-1"
          mobile-breakpoint="993"
          hide-default-footer
          :loading="loading"
          no-data-text="No hay Datos para Mostrar"
          loading-text="Cargando Carpetas... Espere Por favor"
        >
          <template v-slot:item.name="{ item }">
            <div class="link-text text-left">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item.assigned_area="{ item }">
            <div class="link-text text-left">
              {{ item.assigned_area ? item.assigned_area.name : "-" }}
            </div>
          </template>
          <template v-slot:item.business_unit="{ item }">
            <div class="link-text text-left">
              {{
                getFolderDocumentType(item)
                  ? getFolderDocumentType(item).folder.business_unit.name
                  : "-"
              }}
            </div>
          </template>
          <template v-slot:item.folder_container="{ item }">
            <v-autocomplete
              class="mt-3"
              :value="getFolderDocumentType(item).folder.id"
              :items="[
                {
                  id: getFolderDocumentType(item).folder.id,
                  name: getFolderDocumentType(item).folder.name,
                },
                ...foldersDocumentsFromBusinessUnit.items,
              ]"
              label="Selecciona la carpeta"
              item-text="name"
              item-value="id"
              no-data-text="Sin Carpetas Disponibles"
              @update:search-input="
                searchFoldersDocuments(
                  $event,
                  getFolderDocumentType(item).folder
                )
              "
              @focus="
                searchFoldersDocuments(null, getFolderDocumentType(item).folder)
              "
              @change="updateFolderAssociatedNameDocument($event, item)"
            >
            </v-autocomplete>
          </template>
          <template v-slot:item.required="{ item }">
            <div class="d-flex justify-content-center">
              <v-checkbox
                @click="
                  updateRequiredAssociatedNameDocument(
                    !getFolderDocumentType(item).required,
                    item
                  )
                "
                :input-value="
                  getFolderDocumentType(item).required ? true : false
                "
              >
              </v-checkbox>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex align-items-center justify-content-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="openModalUpdateOrCreateNameDocument(item)"
                    icon
                    color="warning"
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    class="mx-2"
                  >
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Editar Nombre de Documento</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="deleteNameDocument(item)"
                    icon
                    color="red darken-2"
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    class="mx-2"
                  >
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar Nombre de Documento</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="filters.page"
            :length="namesDocuments ? namesDocuments.total_pages : 1"
            :total-visible="8"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <modal-update-or-create-name-document
      :modalAction="dialogUpdateOrCreateNameDocument"
      :closeModalCreateOrUpdateNameDocument="
        closeModalCreateOrUpdateNameDocument
      "
      :currentNameDocument="currentNameDocument"
      @nameDocumentCreatedOrUpdated="getNameDocumentsData(1)"
    >
    </modal-update-or-create-name-document>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalUpdateOrCreateNameDocument from "./ModalUpdateOrCreateNameDocument.vue";
import { authUsuarioComputed } from "@/state/helpers";
import { ACTIVE_BUSINESS_UNITS } from "@/constants/presale";

export default {
  components: { ModalUpdateOrCreateNameDocument },
  data() {
    return {
      dialogUpdateOrCreateNameDocument: false,
      currentNameDocument: null,
      loading: false,
      loadingActions: false,
      debounce: null,
      debounceTime: 500,
      filters: {
        page: 1,
        items: 10,
        filter: "",
        business_unit: null,
      },
      tableHeaders: [
        {
          value: "name",
          text: "Nombre del Documento",
          sortable: false,
          width: "20%",
        },
        {
          value: "assigned_area",
          text: "Área Responsable",
          sortable: false,
          width: "15%",
          align: "left",
        },
        {
          value: "business_unit",
          text: "Unidad de Negocio",
          sortable: false,
          align: "left",
          width: "20%",
        },
        {
          value: "folder_container",
          sortable: false,
          align: "left",
          text: "Carpeta Contenedora",
          width: "20%",
        },
        {
          value: "required",
          sortable: false,
          align: "center",
          text: "Obligatorio",
          width: "5%",
        },
        {
          value: "actions",
          sortable: false,
          align: "center",
          text: "Acciones",
          width: "10%",
        },
      ],
    };
  },
  mounted() {
    this.getNameDocumentsData();
  },
  methods: {
    ...mapActions({
      getAllNamesDocuments: "document/getAllNamesDocuments",
      deleteNameDocumentService: "document/deleteNameDocument",
      updateAssociatedNameDocument: "document/updateAssociatedNameDocument",
      getAllfoldersDocuments: "document/getAllfoldersDocuments",
    }),
    async updateRequiredAssociatedNameDocument(value, name_document) {
      let payload = {
        folder_document_type_id: name_document.folder_document_types[0].id,
        required: value,
        updated_by: this.user.email,
        folder_id: name_document.folder_document_types[0].folder.id,
        document_type_id: name_document.id,
      };
      const resp = await this.updateAssociatedNameDocument(payload);
      if (resp.status == 200) {
        this.$swal.fire({
          icon: "success",
          title: "Excelente",
          text: `Valor Obligatorio del Nombre de Documento actualizado correctamente`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
        this.getNameDocumentsData();
      }
      if (!resp.status || resp.status === 400 || resp.status === 500) {
        this.loading = false;
        return this.$swal.fire({
          icon: "error",
          title: "Error",
          text:
            resp.status == 500
              ? "Ha ocurrido un error de servidor, intenta nuevamente"
              : resp.data.detail,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 10000,
          timerProgressBar: true,
        });
      }
    },
    async updateFolderAssociatedNameDocument(value, name_document) {
      if (value) {
        let payload = {
          folder_document_type_id: name_document.folder_document_types[0].id,
          required: this.getFolderDocumentType(name_document).required,
          updated_by: this.user.email,
          folder_id: value,
          document_type_id: name_document.id,
        };
        const resp = await this.updateAssociatedNameDocument(payload);
        if (resp.status == 200) {
          this.$swal.fire({
            icon: "success",
            title: "Excelente",
            text: `Carpeta Contenedora del Nombre de Documento actualizada correctamente`,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
          this.getNameDocumentsData();
        }
        if (!resp.status || resp.status === 400 || resp.status === 500) {
          this.loading = false;
          return this.$swal.fire({
            icon: "error",
            title: "Error",
            text:
              resp.status == 500
                ? "Ha ocurrido un error de servidor, intenta nuevamente"
                : resp.data.detail,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
      }
    },
    async searchFoldersDocuments(search = null, folder = null) {
      if (!search || (typeof search == "string" && search !== folder.name)) {
        clearTimeout(this.debounceFolders);
        let self = this;
        this.debounceFolders = await setTimeout(async function () {
          self.loadingFolders = true;
          await self.getAllfoldersDocuments({
            filter_from_business_unit: true,
            filter: search ? search : undefined,
            items: 20,
          });
          self.loadingFolders = false;
        }, self.debounceTime);
      }
    },
    async getNameDocumentsData(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = await setTimeout(async function () {
        self.loading = true;
        self.filters.page = page;
        await self.getAllNamesDocuments({
          ...self.filters,
          business_unit_code: self.filters.business_unit,
        });
        self.loading = false;
      }, self.debounceTime);
    },
    openModalUpdateOrCreateNameDocument(document) {
      this.currentNameDocument = document;
      this.dialogUpdateOrCreateNameDocument = true;
    },
    closeModalCreateOrUpdateNameDocument() {
      this.currentNameDocument = null;
      this.dialogUpdateOrCreateNameDocument = false;
    },
    async deleteNameDocument(name_document) {
      this.$swal
        .fire({
          icon: "warning",
          title:
            "¿Estás seguro de eliminar el nombre de documento: " +
            "<b>" +
            name_document.name +
            "</b>" +
            "?",
          text: "Esta acción no se puede deshacer",
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          confirmButtonColor: "red",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            this.loadingActions = true;
            let resp = null;
            resp = await this.disassociateNameDocumentService({
              document_type_id: name_document.id,
              folder_id: name_document.folder.id,
            });
            if (resp.status == 200) {
              resp = await this.deleteNameDocumentService({
                type_id: name_document.id,
              });
              if (resp.status == 200) {
                this.$swal.fire({
                  icon: "success",
                  title: "Excelente",
                  text:
                    "El Nombre de Documento: " +
                    "<b>" +
                    name_document.name +
                    "</b>" +
                    " ha sido eliminado",
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 5000,
                  timerProgressBar: true,
                });
                this.getNameDocumentsData();
              }
              if (!resp.status || resp.status === 400) {
                this.loading = false;
                return this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: resp.data.detail,
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 10000,
                  timerProgressBar: true,
                });
              }
            }
            if (!resp.status || resp.status === 400) {
              this.loading = false;
              return this.$swal.fire({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 10000,
                timerProgressBar: true,
              });
            }
            this.loadingActions = false;
          }
        });
    },
    getFolderDocumentType(item) {
      return item.folder_document_types[0];
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      namesDocuments: "document/namesDocuments",
      foldersDocumentsFromBusinessUnit:
        "document/foldersDocumentsFromBusinessUnit",
    }),
    activeBusinessUnits() {
      return this.businessUnits.filter((item) =>
        ACTIVE_BUSINESS_UNITS.includes(item.name)
      );
    },
  },
  watch: {
    "filters.page": function (page) {
      this.getNameDocumentsData(page);
    },
  },
};
</script>

<style></style>
